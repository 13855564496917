<template>
  <div class="flex flex-col gap-4">
    <div
      class="flex flex-col gap-2 sm:flex-row-reverse sm:items-center sm:justify-between"
    >
      <SelectionFilters
        v-if="!readonly"
        v-model:definition="definition"
        :context
      />
      <div class="flex-1">
        <slot></slot>
      </div>
    </div>

    <div class="relative ml-6 flex gap-6">
      <div class="-mb-2 -mt-2 border-l border-dashed border-l-gray-200"></div>
      <div class="flex flex-1 flex-col gap-2" :data-testid="testIds.items">
        <template v-for="criterion in definition.criteria" :key="criterion">
          <div class="relative flex items-center">
            <Icon
              icon="add_circle"
              :size="IconSize.lg"
              class="absolute -left-10 bg-white text-deepteal-200"
            />
            <SelectionCriterionForm
              :criterion="criterion"
              :context="context"
              :edit="activeCriterion === criterion"
              :exclude="exclude"
              :filters="definition.filters"
              :includeObsoleteCriteria="includeObsoleteCriteria"
              class="flex-1"
              @submit="(value) => submitCriterion(criterion, value)"
            >
              <template v-slot:actions>
                <template v-if="!criterion.isReadOnly && !readonly">
                  <button v-if="activeCriterion === criterion" type="submit">
                    <ButtonSubmit
                      data-testid="saveSelectionCriterion"
                      :size="ButtonSize.sm"
                    />
                  </button>
                  <button
                    v-else-if="activeCriterion === undefined"
                    type="button"
                  >
                    <Icon
                      class="cursor-pointer self-end"
                      icon="edit"
                      :color="Color.Gray"
                      :size="IconSize.sm"
                      clickable
                      @click="editCriterion(criterion)"
                    />
                  </button>

                  <button v-if="activeCriterion === criterion" type="button">
                    <Icon
                      data-testid="cancelSelectionCriterion"
                      class="cursor-pointer self-end"
                      icon="close"
                      :color="Color.Gray"
                      :size="IconSize.sm"
                      :stroke="IconStroke.md"
                      clickable
                      @click="cancelActiveCriterion()"
                    />
                  </button>
                  <button v-else type="button">
                    <Icon
                      class="cursor-pointer self-end"
                      icon="delete"
                      :color="Color.Gray"
                      :size="IconSize.sm"
                      @click="removeCriterion(criterion)"
                    />
                  </button>
                </template>
                <template v-else-if="criterion.isReadOnly">
                  <div
                    class="'flex transition-colors' items-center gap-2 rounded bg-deepteal-200 px-2 py-1 text-xs font-medium leading-none text-deepteal-700"
                  >
                    {{
                      texts.navigationItems.mailing.steps.selectionDefinition
                        .requiredCriterion
                    }}
                  </div>
                </template>
              </template>
            </SelectionCriterionForm>
          </div>
        </template>
        <template v-if="newCriterion">
          <div class="relative flex items-center">
            <Icon
              icon="add_circle"
              :size="IconSize.xl"
              class="absolute -left-12 bg-white text-emerald-500"
            />
            <SelectionCriterionForm
              v-if="newCriterion"
              :criterion="newCriterion"
              :context="context"
              :edit="activeCriterion === newCriterion"
              :exclude="exclude"
              :filters="definition.filters"
              :includeObsoleteCriteria="includeObsoleteCriteria"
              class="flex-1"
              @submit="(value) => submitNewCriterion(value)"
            >
              <template v-slot:actions>
                <button v-if="activeCriterion === newCriterion" type="submit">
                  <ButtonSubmit
                    data-testid="saveSelectionCriterion"
                    :size="ButtonSize.sm"
                  />
                </button>
                <button type="button">
                  <Icon
                    class="cursor-pointer self-end"
                    icon="delete"
                    :color="Color.Gray"
                    :size="IconSize.sm"
                    @click="removeNewCriterion"
                  />
                </button>
              </template>
            </SelectionCriterionForm>
          </div>
        </template>
      </div>
    </div>
    <TransitionFade>
      <ButtonAdd
        v-if="!activeCriterion && !readonly"
        class="ml-1 self-start"
        :title="
          texts.navigationItems.mailing.steps.selectionDefinition.create
            .buttonText
        "
        @click="addNewCriterion"
      ></ButtonAdd>
    </TransitionFade>
  </div>
</template>

<script setup lang="ts">
import texts from "@/utils/texts";
import { Color } from "@/enums";
import SelectionCriterionForm from "@/components/selection/SelectionCriterionForm.vue";
import { computed, ref, watchEffect } from "vue";
import Icon from "@/components/common/icon/Icon.vue";
import { CriterionFieldContext } from "@/components/selection/SelectionDefinition.helpers";
import { IconSize, IconStroke } from "@/components/common/icon/Icon.types";
import ButtonSubmit from "../common/button/ButtonSubmit.vue";
import { ButtonSize } from "../common/button/Button.types";
import ButtonAdd from "../common/button/ButtonAdd.vue";
import {
  ISelectionCriterionDto,
  SelectionCriterionDto,
  SelectionCriterionDtoType,
  SelectionDefinitionDto,
} from "@/lib/eduConfigurationServiceClient";
import TransitionFade from "../common/transition/TransitionFade.vue";
import { testIds } from "@/utils/testing";
import SelectionFilters from "@/components/selection/SelectionFilters.vue";
import { isObsoleteCriterion } from "@/models/selection-criterion";

defineProps<{
  context: CriterionFieldContext;
  exclude?: SelectionCriterionDtoType[];
  readonly?: boolean;
}>();

const definition = defineModel<SelectionDefinitionDto>({ required: true });

const activeCriterion = ref<SelectionCriterionDto>();
const newCriterion = ref<SelectionCriterionDto>();

const addNewCriterion = () => {
  const criterion = new SelectionCriterionDto({
    type: SelectionCriterionDtoType.IsRegisteredForActivity,
    isReadOnly: false,
  });
  activeCriterion.value = criterion;
  newCriterion.value = criterion;
};

const submitNewCriterion = (submitted: ISelectionCriterionDto) => {
  definition.value.criteria.push(new SelectionCriterionDto(submitted));

  activeCriterion.value = undefined;
  newCriterion.value = undefined;
};

const removeNewCriterion = () => {
  newCriterion.value = undefined;
  activeCriterion.value = undefined;
};

const submitCriterion = (
  criterion: SelectionCriterionDto,
  submitted: ISelectionCriterionDto,
) => {
  const index = definition.value.criteria.findIndex((c) => c === criterion);
  if (index >= 0) {
    definition.value.criteria[index] = new SelectionCriterionDto(submitted);
  }

  activeCriterion.value = undefined;
};

const removeCriterion = (criterion: SelectionCriterionDto) => {
  const index = definition.value.criteria.findIndex((c) => c === criterion);
  if (index >= 0) {
    definition.value.criteria.splice(index, 1);
  }

  if (activeCriterion.value === criterion) {
    activeCriterion.value = undefined;
  }
};

const editCriterion = (criterion: SelectionCriterionDto) => {
  activeCriterion.value = criterion;
};

const cancelActiveCriterion = () => {
  activeCriterion.value = undefined;
};

const includeObsoleteCriteria = computed<boolean>(() =>
  definition.value.criteria.some((c) => isObsoleteCriterion(c.type)),
);

watchEffect(() => {
  // Sort the criteria so that the required criteria are on top
  definition.value.criteria.sort((a, b) => {
    if (a.isReadOnly === b.isReadOnly) {
      return 0;
    } else if (a.isReadOnly) {
      return -1;
    } else {
      return 1;
    }
  });
});
</script>
