import { Culture } from "@/enums";
import TargetAudience from "@/models/targetAudience";
import { LocalizedQuestionWithDiscreteAnswers } from "@/lib/formsServiceClient";
import {
  ISelectionFiltersDto,
  LocalizedActivityDTO,
  LocalizedStudyProgramDTO,
  MailingSelectionDefinitionDto,
  PreEducationLevelDTO,
  RecruitmentYearDTO,
  SelectionCriterionDto,
  SelectionCriterionDtoType,
  SelectionDefinitionDto,
  ThirdPartyProspectSourceDto,
} from "@/lib/eduConfigurationServiceClient";
import LanguageCriterionField from "./type/LanguageCriterionField.vue";
import SessionCriterionField from "./type/SessionCriterionField.vue";
import StudyProgramCriterionField from "./type/StudyProgramCriterionField.vue";
import MailingCriterionField from "./type/MailingCriterionField.vue";
import PreEducationCriterionField from "./type/PreEducationCriterionField.vue";
import AnswerCriterionField from "./type/AnswerCriterionField.vue";
import TargetAudienceCriterionField from "./type/TargetAudienceCriterionField.vue";
import ActivityCriterionField from "./type/ActivityCriterionField.vue";
import ThirdPartyProspectSourceCriterionField from "./type/ThirdPartyProspectSourceCriterionField.vue";
import { SessionsForActivity } from "@/models/session";
import { eduConfigurationServiceClient } from "@/services/eduConfigurationService.client.service";
import { formsServiceClient } from "@/services/formsService.client.service";
import store from "@/store/index";
import MultiActivityCriterionField from "@/components/selection/type/MultiActivityCriterionField.vue";

export type CriterionFieldProps = {
  criterion: SelectionCriterionDto;
  context: CriterionFieldContext;
  filters: ISelectionFiltersDto;
  edit?: boolean;
  exclude?: SelectionCriterionDtoType[];
  includeObsoleteCriteria?: boolean;
};

export type CriterionFieldContext = {
  recruitmentYear: RecruitmentYearDTO;
  activities: LocalizedActivityDTO[];
  studyPrograms: LocalizedStudyProgramDTO[];
  targetAudiences: TargetAudience[];
  languages: Culture[];
  questionsWithAnswers: LocalizedQuestionWithDiscreteAnswers[];
  sessions: SessionsForActivity;
  mailings: MailingSelectionDefinitionDto[];
  preEducationLevels: PreEducationLevelDTO[];
  thirdPartyProspectSources: ThirdPartyProspectSourceDto[];
};

export const typeToComponentMapper = new Map<
  SelectionCriterionDtoType,
  unknown
>([
  [
    SelectionCriterionDtoType.IsRegisteredForActivity,
    MultiActivityCriterionField,
  ],
  [
    SelectionCriterionDtoType.IsNotRegisteredForActivity,
    MultiActivityCriterionField,
  ],
  [SelectionCriterionDtoType.HasVisitedActivity, MultiActivityCriterionField],
  [
    SelectionCriterionDtoType.HasNotVisitedActivity,
    MultiActivityCriterionField,
  ],
  [
    SelectionCriterionDtoType.NotSubmittedSurveyForVisitedActivity,
    ActivityCriterionField,
  ],
  [SelectionCriterionDtoType.IsInTargetAudience, TargetAudienceCriterionField],
  [SelectionCriterionDtoType.IsNotTargetAudience, TargetAudienceCriterionField],
  [SelectionCriterionDtoType.HasLanguage, LanguageCriterionField],
  [SelectionCriterionDtoType.HasGivenAnswer, AnswerCriterionField],
  [SelectionCriterionDtoType.IsRegisteredForSession, SessionCriterionField],
  [
    SelectionCriterionDtoType.IsInterestedInStudyProgram,
    StudyProgramCriterionField,
  ],
  [SelectionCriterionDtoType.HasApplied, StudyProgramCriterionField],
  [SelectionCriterionDtoType.NotReceivedMailing, MailingCriterionField],
  [SelectionCriterionDtoType.HasPreEducation, PreEducationCriterionField],
  [
    SelectionCriterionDtoType.ThirdPartyProspectSource,
    ThirdPartyProspectSourceCriterionField,
  ],
]);

export const loadCriterionFieldContext = async (
  activityId?: string,
): Promise<CriterionFieldContext> => {
  const [
    recruitmentYearDto,
    activityDtos,
    studyProgramDtos,
    targetAudienceDtos,
    questionDtos,
    mailingDtos,
    preEducationLevels,
    thirdPartyProspectSourceIds,
  ] = await Promise.all([
    eduConfigurationServiceClient.getTenantRecruitmentYear(),
    eduConfigurationServiceClient.getActivities(
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
    ),
    eduConfigurationServiceClient.getStudyPrograms(true),
    eduConfigurationServiceClient.getTargetAudiences(),
    formsServiceClient.getQuestionsWithDiscreteAnswers(),
    eduConfigurationServiceClient.getMailingsForSelectionDefinitions(),
    eduConfigurationServiceClient.getAllPreEducationLevels(),
    eduConfigurationServiceClient.getAllThirdPartyProspectSources(),
  ]);

  return {
    recruitmentYear: recruitmentYearDto,
    activities: activityDtos,
    studyPrograms: studyProgramDtos,
    targetAudiences: targetAudienceDtos,
    languages: store.getters["cultureStore/available"],
    questionsWithAnswers: questionDtos,
    mailings: mailingDtos,
    preEducationLevels: preEducationLevels.levels,
    sessions: {
      activityId: activityId,
      sessions: activityId
        ? await eduConfigurationServiceClient.getSessions(activityId)
        : [],
    },
    thirdPartyProspectSources: thirdPartyProspectSourceIds,
  };
};

export function fetchSelectionResults(definiton: SelectionDefinitionDto) {
  if (definiton.criteria.length > 0) {
    return eduConfigurationServiceClient.getSelectionResultCountWithDistinctProspectCount(
      definiton,
    );
  }
}
