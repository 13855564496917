<template>
  <div class="flex h-full w-full max-w-3xl flex-col gap-8">
    <div class="flex flex-col gap-2">
      <PageHeading>
        {{ componentTexts.edit.slideOver.detailsSection.title }}
      </PageHeading>
      <PageSubTitle>
        {{ componentTexts.edit.slideOver.detailsSection.description }}
      </PageSubTitle>
    </div>
    <form
      v-if="registrationLink"
      class="flex flex-col gap-8"
      @submit="submitForm"
    >
      <RegistrationLinkEditActions :regLink="registrationLink" />

      <Divider />

      <SectionHeading
        :divider="false"
        :title="componentTexts.edit.slideOver.detailSection.title"
        :description="componentTexts.edit.slideOver.detailSection.description"
      />

      <LocalizationTabs
        v-model="activeCulture"
        :localizations="cultures"
        @add="addLocale"
        @remove="removeLocale"
      />
      <FormFieldTextInput
        v-for="culture in cultures"
        v-show="activeCulture === culture"
        :id="`localizations.${culture}.name`"
        :key="culture"
        :label="texts.models.registrationLink.name"
        :data-testid="`name_${culture}`"
      />

      <FormFieldToggle
        id="isVisibleForProspects"
        :label="
          texts.navigationItems.organize.activity.form.isVisibleForProspects
        "
      />

      <div class="flex flex-col gap-1">
        <FormFieldSelect
          id="confirmationEmailTemplateId"
          :label="texts.models.registrationLink.confirmationEmailTemplate"
          :items="
            settings.emailTemplates.map((et) => ({
              label: et.name,
              value: et.id,
            }))
          "
          allowUnset
        />
        <RouterLinkAuth
          v-if="form.values.confirmationEmailTemplateId"
          :to="{
            name: RouteNamesSettings.EMAIL_EDIT_EMAIL_TEMPLATE,
            params: { id: form.values.confirmationEmailTemplateId },
          }"
        >
          <span
            class="text-sm text-emerald-500 underline hover:text-emerald-700"
          >
            {{ componentTexts.edit.slideOver.detailSection.editEmailTemplate }}
          </span>
        </RouterLinkAuth>
      </div>

      <div class="flex items-center gap-2">
        <FormFieldDateTime
          id="registrationStartDateTime"
          :label="texts.models.registrationLink.registrationStartDateTime"
          class="flex-1"
        />
        <FormFieldDateTime
          id="registrationEndDateTime"
          :label="texts.models.registrationLink.registrationEndDateTime"
          class="flex-1"
        />
      </div>

      <Divider />

      <SectionHeading
        :divider="false"
        :title="componentTexts.edit.slideOver.formSection.title"
        :description="componentTexts.edit.slideOver.formSection.description"
      />
      <Alert v-if="localizedForm?.isTemplate" :color="Color.Warning">
        <span>{{ texts.models.registrationLink.formIsTemplate }}</span>
        <RouterLinkAuth
          :to="{
            name: RouteNamesSettings.FORMS_EDIT_TEMPLATE,
            params: { id: localizedForm.id },
          }"
          class="font-semibold"
          ><TextButton
            :label="
              texts.navigationItems.organize.activity.registrationLinks.edit
                .slideOver.detailSection.editForm
            "
            :color="Color.Warning"
          />
        </RouterLinkAuth>
      </Alert>

      <FormTemplateEditor
        v-else-if="registrationForm"
        :key="editorKey"
        v-model="registrationForm"
      >
        <template v-slot:heading>
          <ButtonSettings @click="slideOverSettingsOpen = true" />
        </template>
      </FormTemplateEditor>

      <div
        v-if="registrationLink && registrationForm"
        class="flex items-center gap-2"
      >
        <ButtonSubmit v-if="!deleting" :loading="submitting" />
        <ButtonDelete v-if="!deleting" @click="deleting = true" />
        <SlideOverDeleteConfirm
          v-if="deleting"
          :loading="deleteLoading"
          @confirm="submitDelete"
          @cancel="deleting = false"
        />
      </div>
    </form>

    <SlideOverFormTemplateSettings
      v-if="registrationForm"
      v-model:visible="slideOverSettingsOpen"
      :title="texts.models.formStylingSettings.specificSettings"
      :description="texts.models.formStylingSettings.specificDescription"
      :formUri="registrationForm ? registrationForm.formUri : undefined"
      @create:succeeded="reloadFormStyling"
    />
  </div>
</template>

<script setup lang="ts">
import ButtonDelete from "@/components/common/button/ButtonDelete.vue";
import ButtonSettings from "@/components/common/button/ButtonSettings.vue";
import ButtonSubmit from "@/components/common/button/ButtonSubmit.vue";
import FormFieldDateTime from "@/components/common/datetime/FormFieldDateTime.vue";
import Divider from "@/components/common/divider/Divider.vue";
import PageHeading from "@/components/common/page-heading/PageHeading.vue";
import PageSubTitle from "@/components/common/page-heading/PageSubTitle.vue";
import SectionHeading from "@/components/common/section/SectionHeading.vue";
import FormFieldSelect from "@/components/common/select/FormFieldSelect.vue";
import SlideOverDeleteConfirm from "@/components/common/slideover/SlideOverDeleteConfirm.vue";
import FormFieldTextInput from "@/components/common/text-input/FormFieldTextInput.vue";
import FormFieldToggle from "@/components/common/toggle/FormFieldToggle.vue";
import LocalizationTabs from "@/components/localization-tabs/LocalizationTabs.vue";
import RouterLinkAuth from "@/components/router/router-link-auth/RouterLinkAuth.vue";
import { Color, Culture } from "@/enums";
import { RegistrationLinkDTO } from "@/lib/eduConfigurationServiceClient";
import { FormDTO, FormType, LocalizedFormDTO } from "@/lib/formsServiceClient";
import router from "@/router/index";
import {
  RouteNamesActivityOverview,
  RouteNamesSettings,
} from "@/router/routeNames";
import { eduConfigurationServiceClient } from "@/services/eduConfigurationService.client.service";
import { formsServiceClient } from "@/services/formsService.client.service";
import settings from "@/store/context/settings.context";
import store from "@/store/index";
import Notify from "@/utils/notify";
import texts from "@/utils/texts";
import {
  convertToEditFormValues,
  convertToUpdateDTO,
  RegistrationLinkEditFormValues,
} from "@/views/organize/activities/activity/registration-links/components/RegistrationLinkForm.types";
import FormTemplateEditor from "@/views/settings/forms/components/form-template-editor/FormTemplateEditor.vue";
import SlideOverFormTemplateSettings from "@/views/settings/forms/SlideOverFormStylingSettings.vue";
import { DateTime } from "luxon";
import { useForm } from "vee-validate";
import { computed, ref, watch } from "vue";
import * as yup from "yup";
import RegistrationLinkEditActions from "./RegistrationLinkEditActions.vue";
import Alert from "@/components/common/alert/Alert.vue";
import TextButton from "@/components/common/button/TextButton.vue";

const props = defineProps<{
  linkId: string;
}>();

const componentTexts =
  texts.navigationItems.organize.activity.registrationLinks;

// Fetch registration link
const error = ref(false);
const registrationLink = ref<RegistrationLinkDTO>();
const registrationForm = ref<FormDTO>();
const allForms = ref<LocalizedFormDTO[]>([]);
const localizedForm = computed<LocalizedFormDTO | undefined>(() =>
  allForms.value.find((f) => f.id === registrationLink.value?.formId),
);

const fetchRegistrationLinkAndForm = async () => {
  try {
    registrationLink.value =
      await eduConfigurationServiceClient.getRegistrationLinkById(props.linkId);

    allForms.value = await formsServiceClient.getForms(FormType.Registration);

    registrationForm.value = await formsServiceClient.getForm(
      registrationLink.value.formId,
    );
  } catch (e) {
    error.value = true;
    throw e;
  }
};
fetchRegistrationLinkAndForm();

const form = useForm<RegistrationLinkEditFormValues>({
  validationSchema: yup.object({
    localizations: yup.object(
      settings.availableLanguages.reduce(
        (acc, language) => ({
          ...acc,
          [language.locale.value as Culture]: yup.lazy((value) =>
            value
              ? yup.object({
                  locale: yup.mixed<Culture>().required(),
                  name: yup.string().max(256).required(),
                  prospectAppDescription: yup.string().max(1024),
                })
              : yup.mixed(),
          ),
        }),
        {},
      ),
    ),
    confirmationEmailTemplateId: yup.string(),
    registrationStartDateTime: yup.mixed<DateTime>(),
    registrationEndDateTime: yup
      .mixed<DateTime>()
      .isLaterThan(
        "registrationStartDateTime",
        texts.validation.endDateTimeMinimumStartDateTime,
      ),
  }),
});

const defaultCulture = store.getters["cultureStore/mainCulture"] as Culture;
const activeCulture = ref(Culture.NL);
const cultures = computed(() =>
  form.values.localizations
    ? (Object.keys(form.values.localizations) as Culture[])
    : [defaultCulture],
);

function addLocale(culture: Culture) {
  form.setFieldValue(
    "localizations",
    addToLocalizations(form.values.localizations, culture, {
      locale: culture,
      name: "",
    }),
  );
}

function removeLocale(culture: Culture) {
  form.setFieldValue(
    "localizations",
    removeFromLocalizations(form.values.localizations, culture),
  );
}

function addToLocalizations<T>(
  localizations: Record<Culture, T>,
  culture: Culture,
  value: T,
) {
  return { ...localizations, [culture]: value };
}

function removeFromLocalizations<T>(
  localizations: Record<Culture, T>,
  culture: Culture,
) {
  const newDict = { ...localizations };
  delete newDict[culture];
  return newDict;
}

const editorKey = ref(0);
const slideOverSettingsOpen = ref<boolean>(false);
const reloadFormStyling = () => {
  editorKey.value++;
};

// Edit
const submitting = ref(false);
const submitForm = form.handleSubmit(async (values) => {
  if (!registrationLink.value || !registrationForm.value || !values) {
    throw new Error("Could not submit form because of missing data");
  }

  try {
    submitting.value = true;

    const dto = convertToUpdateDTO(props.linkId, values);

    await eduConfigurationServiceClient.updateRegistrationLink(
      registrationLink.value.activityId,
      registrationLink.value.id,
      dto,
    );

    await formsServiceClient.updateForm(
      registrationLink.value.formId,
      registrationForm.value,
    );

    Notify.success(componentTexts.edit.success);
  } catch (e) {
    Notify.failure(componentTexts.edit.failure);
    throw e;
  } finally {
    submitting.value = false;
  }
});

// Delete
const deleting = ref<boolean>(false);
const deleteLoading = ref<boolean>(false);
const submitDelete = async () => {
  if (!registrationLink.value) {
    throw new Error(
      "Could not delete registration link because of missing data",
    );
  }

  try {
    deleteLoading.value = true;

    await eduConfigurationServiceClient.deleteRegistrationLink(
      registrationLink.value.activityId,
      registrationLink.value.id,
    );

    Notify.success(componentTexts.delete.success);

    router.push({
      name: RouteNamesActivityOverview.REGISTRATIONLINKS,
      query: { reload: "true" },
    });
  } catch (e) {
    Notify.failure(componentTexts.delete.failure);
    throw e;
  } finally {
    deleteLoading.value = false;
  }
};

watch(
  registrationLink,
  (newRegLink) => {
    if (newRegLink) {
      const values = convertToEditFormValues(newRegLink, activeCulture.value);
      form.setValues(values);
    }
  },
  { immediate: true },
);

watch(
  registrationForm,
  (newValue) => {
    if (newValue) {
      registrationForm.value = newValue;
    }
  },
  { deep: true },
);
</script>
