<template>
  <Page :loading="loading" :error="error" class="flex flex-col gap-2">
    <ButtonBack :toRouteName="texts.generic.previous" @click="router.go(-1)" />
    <FormTemplateEditor
      v-if="dto"
      :key="editorKey"
      v-model="dto"
      @submit="handleSubmit"
    >
      <template v-slot:heading>
        <ButtonSettings @click="slideOverSettingsOpen = true"></ButtonSettings>
        <ButtonSubmit :loading="submitting">{{
          texts.actions.submit
        }}</ButtonSubmit>
      </template>
      <template v-slot:actions>
        <ButtonDelete
          :disabled="submitting"
          @click="deleteModalVisible = true"
        />
        <ButtonCopy :loading="isCopying" @click="handleCopy" />
        <ButtonCancel @click="handleCancel" />
        <ButtonSubmit :loading="submitting">{{
          texts.actions.submit
        }}</ButtonSubmit>
      </template>
    </FormTemplateEditor>
  </Page>

  <FormTemplateDeleteModal
    v-model:visible="deleteModalVisible"
    :formTemplateId="id"
    @delete:succeeded="router.push({ name: RouteNamesSettings.FORMS })"
  />
  <SlideOverFormTemplateSettings
    v-if="dto"
    v-model:visible="slideOverSettingsOpen"
    :title="texts.models.formStylingSettings.specificSettings"
    :description="texts.models.formStylingSettings.specificDescription"
    :formUri="dto ? dto.formUri : undefined"
    @create:succeeded="reloadFormStyling"
  />
</template>

<script setup lang="ts">
import ButtonBack from "@/components/common/button/ButtonBack.vue";
import ButtonCancel from "@/components/common/button/ButtonCancel.vue";
import ButtonCopy from "@/components/common/button/ButtonCopy.vue";
import ButtonDelete from "@/components/common/button/ButtonDelete.vue";
import ButtonSubmit from "@/components/common/button/ButtonSubmit.vue";
import Page from "@/components/common/page/Page.vue";
import { FormDTO } from "@/lib/formsServiceClient";
import logger from "@/plugins/logger";
import { RouteNamesSettings } from "@/router/routeNames";
import { formsServiceClient } from "@/services/formsService.client.service";
import { loadForms } from "@/store/context/settings.context";
import Notify from "@/utils/notify";
import texts from "@/utils/texts";
import FormTemplateDeleteModal from "@/views/settings/forms/components/FormTemplateDeleteModal.vue";
import FormTemplateEditor from "@/views/settings/forms/components/form-template-editor/FormTemplateEditor.vue";
import { ref } from "vue";
import { useRouter } from "vue-router";
import "./FormExtensions";
import ButtonSettings from "@/components/common/button/ButtonSettings.vue";
import SlideOverFormTemplateSettings from "./SlideOverFormStylingSettings.vue";
import { FormTemplateFormValues } from "@/views/settings/forms/components/form-template-editor/FormTemplateEditor.vue";
import { Culture } from "@/enums";

const router = useRouter();
const slideOverSettingsOpen = ref<boolean>(false);

const props = defineProps<{
  id: string;
}>();

const loading = ref(true);
const error = ref(false);
const editorKey = ref(0);

const dto = ref<FormDTO>();

const initialize = async () => {
  loading.value = true;
  error.value = false;
  try {
    const response = await formsServiceClient.getForm(props.id);
    dto.value = response;
  } catch (e) {
    logger.error(e);
    error.value = true;
  } finally {
    loading.value = false;
  }
};

initialize();

const reloadFormStyling = () => {
  editorKey.value++;
};

const submitting = ref(false);
const handleSubmit = (values: FormTemplateFormValues) => {
  submitting.value = true;

  dto.value?.localizations.forEach((localization) => {
    localization.name =
      values.localizations[localization.locale as Culture].label;
    localization.label =
      values.localizations[localization.locale as Culture].label;
  });

  formsServiceClient
    .updateForm(props.id, dto.value)
    .then(() => loadForms())
    .then(() => {
      Notify.success(texts.notifications.edit.successCached, [
        texts.models.formTemplate.title,
      ]);
      router.go(-1);
    })
    .catch((e) => {
      Notify.failure(
        {
          title: texts.notifications.edit.failure.title,
          content: e.response ?? texts.notifications.edit.failure.content,
        },
        [texts.models.formTemplate.title],
      );
      logger.error(e);
    })
    .finally(() => {
      submitting.value = false;
    });
};

const deleteModalVisible = ref<boolean>(false);

const handleCancel = () => {
  router.push({ name: RouteNamesSettings.FORMS });
};

const isCopying = ref(false);
const handleCopy = () => {
  isCopying.value = true;
  formsServiceClient
    .copyForm(props.id)
    .then((response) => {
      Notify.success(texts.notifications.copy.success, [
        texts.models.formTemplate.title,
      ]);

      router.push({
        name: RouteNamesSettings.FORMS_EDIT_TEMPLATE,
        params: { id: response.id },
      });
    })
    .catch((error) => {
      logger.log(error);

      Notify.failure(texts.notifications.copy.failure, [
        texts.models.formTemplate.title,
      ]);
    })
    .finally(() => {
      isCopying.value = false;
    });
};
</script>
